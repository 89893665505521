@media (max-width: 1460px) and (min-width: 1280px) {
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  main.inner {
    padding-bottom: var(--rowGap);
  }
  .icon-banners-wrapper {
    gap: 16px;
  }
  .mainpage-greeting {
    padding-block: 72px;
  }
  main.aside {
    grid-column-gap: 32px;
  }
  .detail-main-wrapper {
    gap: 0 48px;
  }
  .footer-content {
    padding: 24px 10px !important;
  }
  .footer-content a {
    font-size: 13px !important;
  }
  .footer-left,
  .footer-right {
    gap: 16px;
    z-index: 105;
  }
  #search-engine {
    width: 300px !important;
  }
  body.show-search-engine .shop-header-container .logo {
    transform: translateX(-105%);
  }
  #detail-images {
    --swiper-navigation-sides-offset: 10px !important;
  }
}
@media (max-width: 1279px) {
  :root {
    --fontSize: 13px;
    --lineHeight: 1.4;
  }
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .detail-omnibus {
    text-align: left;
  }
  main {
    padding-bottom: var(--rowGap) !important;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    grid-gap: var(--rowGap) 0;
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 15px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #container {
    overflow: clip;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    height: 80px;
    margin-top: -40px !important;
  }
  #detail-images .swiper-button-prev {
    left: -45px;
  }
  #detail-images .swiper-button-next {
    right: -45px;
  }
  .headline {
    font-size: 26px !important;
    margin-bottom: 10px;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  #search-engine {
    display: none;
  }
  .newsletter-bg {
    background-image: none !important;
  }
  .newsletter-input {
    width: 100%;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 10px;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
  }
  navigation-wrapper {
    gap: 0px 16px !important;
  }
  #filter {
    display: none;
  }
  .product-images-mini {
    width: 100%;
    height: auto;
    margin-top: 10px;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .swiper-slide {
    width: 10px;
    height: 10px;
    background-color: #DEDDD9;
    border-radius: 50%;
    margin: 0 5px;
  }
  .product-images-mini img {
    display: none !important;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  :root {
    --iconSize: 20px;
  }
  .top-bar {
    padding-block: 4px;
  }
  .top-bar > div {
    padding: 0 26px;
  }
  .top-bar .top-bar-close {
    right: 10px;
    width: 20px;
  }
  .top-bar .top-bar-close:before {
    font-size: 18px;
  }
  .header-contact {
    gap: 16px;
  }
  .header-contact a {
    padding-left: 26px;
  }
  .header-contact a.email:after {
    top: -1px;
  }
  #main-bar {
    display: none;
  }
  .shop-header-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px 0;
  }
  .shop-header-container .logo {
    top: 50%;
    transform: translate(-50%, -50%);
    height: 68px;
    max-height: 100%;
  }
  .shop-header-container .header-tools-wrapper {
    margin-left: auto;
    margin-bottom: 0;
    padding-bottom: 0;
    align-items: center;
    gap: 24px;
  }
  .search-engine-trigger,
  .log-button,
  #cart-box,
  .tree-trigger {
    padding-top: 28px;
  }
  .search-engine-trigger:before,
  .log-button:before,
  #cart-box:before,
  .tree-trigger:before {
    font-size: 22px;
  }
  .hamburger-icon {
    height: 22px;
    gap: 8px;
  }
  .hamburger-icon > span {
    width: 85%;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #000000;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  .main-banner .swiper-slide {
    height: auto;
    aspect-ratio: 1440/640;
  }
  .text-wrapper-container {
    width: 100%;
  }
  .text-wrapper-container .text-wrapper {
    top: 32px;
  }
  .text-wrapper-container .text-wrapper > b {
    font-size: 26px;
  }
  .text-wrapper-container .text-wrapper > span {
    font-size: var(--fontSize);
  }
  .text-wrapper-container .text-wrapper > .slide-button {
    padding: 10px 28px;
    margin-top: 12px;
  }
  .text-wrapper-container .text-wrapper > .slide-button.plays-video {
    width: 60px;
    height: 60px;
  }
  .text-wrapper-container .text-wrapper > .slide-button.plays-video:before {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 20px solid white;
  }
  .mainpage-greeting {
    padding-block: var(--rowGap);
  }
  .mainpage-greeting .headline {
    gap: 20px;
    margin-bottom: 20px;
  }
  .icon-banners-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    margin-top: var(--rowGap);
    gap: 32px 16px;
  }
  .icon-banner {
    gap: 8px;
  }
  .icon-banner figure {
    width: 64px;
  }
  .icon-banner > p {
    font-size: 14px;
  }
  .alternating-banner .alternating-text {
    margin-inline: 32px;
  }
  .alternating-banner .alternating-text .headline {
    gap: 20px;
    margin-bottom: 20px;
  }
  .alternating-banner .alternating-text > a {
    margin-top: 32px;
    padding: 10px 28px;
  }
  .promo-bar {
    padding-block: 26px !important;
    gap: 16px 32px;
  }
  .promo-bar a {
    padding: 10px 28px;
  }
  .sliding-banners-wrapper {
    margin-inline: 32px;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .footer-content .logo {
    position: static;
    order: -1;
    gap: 20px;
    transform: none !important;
    height: 68px;
    align-self: center;
    margin-bottom: -6px;
  }
  .footer-left,
  .footer-right {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    flex-wrap: nowrap;
    width: 100%;
    max-width: none;
  }
  .footer-bottom-bar {
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
  }
  .sidebar-banners-wrapper {
    display: none;
  }
  .inner.aside {
    display: flex;
    flex-direction: column;
  }
  main.aside {
    margin-top: 0px !important;
    margin-top: 0 !important;
  }
  #breadcrumb {
    padding-block: 10px !important;
  }
  .category-headline {
    margin-bottom: 10px;
  }
  .navigation-wrapper {
    margin-bottom: var(--rowGap);
  }
  .pagination {
    margin-top: calc(var(--rowGap) + 16px);
    gap: 32px;
  }
  body:not([data-type="detail"]) .product-badges {
    gap: 1px;
  }
  body:not([data-type="detail"]) .product-badges span {
    font-size: 12px;
    font-weight: 600;
    padding: 2px 4px;
  }
  a.product-name {
    margin-top: 12px;
  }
  .short-desc {
    font-size: var(--fontSize);
  }
  [data-type="detail"] #content {
    gap: var(--rowGap);
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev {
    left: 10px;
    height: 48px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
    height: 48px;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .product-images-mini .size-custom {
    padding: 0 !important;
  }
  #detail-info > * {
    justify-content: center;
  }
  .detail-headline {
    margin-top: 0;
    text-align: center;
    padding-left: 0;
  }
  .detail-headline:after {
    display: none;
  }
  .detail-short-description {
    text-align: center;
  }
  .detail-availability-wrapper {
    text-align: center;
  }
  .shipment-popup-wrapper {
    display: flex;
    margin-top: 0;
    padding: 8px 0;
    margin-bottom: -8px;
    justify-content: center;
  }
  .detail-info-midsection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    text-align: center;
  }
  .detail-info-midsection .detail-pricing {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    order: 1;
  }
  .detail-info-midsection .detail-pricing > del,
  .detail-info-midsection .detail-pricing > .promo-text {
    position: relative;
    top: -4px;
  }
  .detail-info-midsection .detail-pricing > b {
    order: -1;
    font-size: 26px;
  }
  .detail-info-midsection .detail-omnibus {
    order: 2;
    top: 0;
    margin-top: -15px;
    align-self: center;
  }
  .detail-info-midsection .detail-ask-button {
    order: 3;
    margin-bottom: -4px;
  }
  .detail-options {
    display: flex;
    flex-direction: column;
    gap: inherit;
  }
  [data-type="detail"] .headline {
    margin-bottom: 20px;
  }
  .detail-main-info {
    margin-top: 0;
    gap: var(--rowGap);
  }
  #detail-traits .trait {
    gap: 24px;
  }
  #detail-traits .trait b {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex: 1;
  }
  #detail-traits .trait .trait-desc {
    flex: 1;
  }
  .detail-comments-header {
    margin-bottom: 20px;
    gap: 4px 16px;
  }
  .detail-comments-header .stars-rating.average-rating {
    top: 1px;
  }
  .detail-comments-header:after {
    margin-left: 0;
  }
  .total-votes-wrapper {
    top: -8px;
  }
  .comments-list {
    display: flex;
    flex-direction: column;
    gap: var(--rowGap);
    margin-bottom: 0;
  }
  .comment-info .added-date {
    margin-left: 0;
  }
  .comment:last-child .comment-response {
    margin-bottom: 20px;
  }
  #content:has(.infobox) {
    grid-column: 1 / 3 !important;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
@media (max-width: 1279px) and (max-width: 900px) {
  .product-price {
    flex-direction: column;
    gap: 2px;
    align-items: flex-start;
  }
}
@media (max-width: 1100px) {
  .header-social-links {
    display: none;
  }
  .text-wrapper-container .text-wrapper {
    top: 16px;
    gap: 8px;
  }
  .text-wrapper-container .text-wrapper > .slide-button {
    margin-top: 8px;
  }
  .shop-header-container .header-tools-wrapper {
    gap: 16px;
  }
  #detail-traits {
    gap: 0;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 8px 0;
  }
  #detail-traits .trait b {
    width: 100%;
    justify-content: flex-start;
  }
  #detail-traits .trait .trait-desc {
    width: 100%;
  }
  .alternating-banner .headline,
  .mainpage-greeting .headline {
    gap: 16px !important;
    margin-bottom: 16px !important;
  }
  .alternating-banner .headline:after,
  .mainpage-greeting .headline:after {
    height: 18px !important;
  }
  .alternating-banner .alternating-text > a {
    margin-top: 16px;
  }
  .alternating-banner .alternating-text {
    margin-inline: 18px;
  }
  .header-contact a.email:after {
    top: 0;
  }
  .comment-response {
    margin-left: 16px;
  }
  .comment cite {
    padding: 18px 24px;
  }
  .comment-response {
    padding: 16px 24px;
  }
  .comment-response cite {
    padding: 6px 12px;
  }
}
@media (max-width: 1100px) and (max-width: 820px) {
  .header-contact {
    flex-direction: column;
    gap: 8px;
    margin-block: -8px;
    align-items: flex-start !important;
  }
  .header-contact a {
    padding-left: 0;
    display: flex;
    gap: 6px;
    align-items: center;
  }
  .header-contact a:before {
    font-size: 18px;
    position: static;
    transform: none;
  }
  .header-contact a.email:after {
    left: 11px;
    top: 1px;
    width: 6px;
    height: 6px;
  }
}
@media (max-width: 1100px) and (max-width: 840px) {
  .alternating-banners-wrapper {
    gap: calc(var(--rowGap) + 5px);
  }
  .alternating-banner {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
    gap: 16px;
  }
  .alternating-banner .alternating-image {
    width: 100%;
    max-width: 720px;
  }
  .alternating-banner .alternating-text {
    order: 1 !important;
    text-align: center;
    align-items: center;
  }
  .alternating-banner .alternating-text a {
    margin-bottom: 0 !important;
  }
  .alternating-banner .alternating-text .headline:after {
    display: none;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .dropdown-picker:not([data-dropdown-type="sort"]) .dropdown-button {
    min-width: 0;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    width: 30px;
    height: 30px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .navigation-wrapper {
    padding-top: 0;
    border-top: 0;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 1;
  }
  .detail-amount {
    width: 100%;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  #pills-container:not(:empty) {
    margin-bottom: 20px;
  }
  .detail-amount button:before {
    font-size: 22px !important;
  }
  .shop-header.stuck {
    top: -75px;
  }
  .shop-header-container {
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 8px !important;
    padding: 9px 0 8px 0 !important;
  }
  .shop-header-container .logo {
    order: -1 !important;
    position: static !important;
    transform: none !important;
    margin: 0 auto;
  }
  .shop-header-container .header-tools-wrapper {
    width: 100%;
    margin-left: 0 !important;
    justify-content: space-evenly;
  }
  .shop-header-container .header-contact {
    display: none !important;
  }
  .promo-bar .headline {
    font-size: 21px !important;
  }
  .text-wrapper-container .text-wrapper {
    top: 18px;
    gap: 8px;
    max-width: 95%;
  }
  .text-wrapper-container .text-wrapper > b {
    font-size: 18px;
  }
  .text-wrapper-container .text-wrapper > span {
    display: none;
  }
  .text-wrapper-container .text-wrapper > .slide-button {
    font-size: 10px;
    margin-top: 8px;
    padding: 10px 18px;
  }
  .icon-banners-wrapper {
    gap: 16px;
  }
  .text-wrapper-container .text-wrapper > .slide-button.plays-video {
    width: 40px;
    height: 40px;
  }
  .text-wrapper-container .text-wrapper > .slide-button.plays-video:before {
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 14px solid white;
    left: calc(50% + 2px);
  }
  a.cart-back-link {
    font-size: 10px;
    font-weight: 600;
    padding: 4px 7px 3px 5px;
    gap: 3px;
  }
  a.cart-back-link:before {
    font-weight: normal;
    font-size: 14px;
    position: relative;
  }
  .swiper-pagination.banner-pagination .swiper-pagination-bullet {
    height: 32px;
  }
}
@media (max-width: 600px) and (max-width: 400px) {
  .text-wrapper-container .text-wrapper {
    top: 8px;
  }
  .text-wrapper-container .text-wrapper > .slide-button {
    margin-top: 4px;
  }
}
@media (max-width: 374px) {
  .navigation-wrapper {
    margin-top: 20px;
    flex-direction: column;
    gap: 8px;
  }
  .navigation-wrapper .dropdown-picker[data-dropdown-type="pageSize"] {
    width: 100%;
  }
  .navigation-wrapper .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    min-width: 0;
    width: 100%;
  }
  .filter-trigger {
    padding: 0 15px;
    margin-top: 4px !important;
    min-width: auto;
    justify-content: center;
  }
}
