// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1460px) and (min-width: 1280px) {
    .inner {
        width: 100%;
        padding: 0 10px;
    }

    main.inner {
        padding-bottom: var(--rowGap);
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////


    .icon-banners-wrapper {
        gap: 16px;
    }

    .mainpage-greeting {
        padding-block: 72px;
    }

    main.aside { 
        grid-column-gap: 32px;
    }

    .detail-main-wrapper {
        gap: 0 48px;
    }

    .footer-content {
        padding: 24px 10px !important;

        a {
            font-size: 13px !important;
        }
    }

    .footer-left, .footer-right {
        gap: 16px;
        z-index: 105;

    }

    #search-engine {
        width: 300px !important;
    }

    body.show-search-engine .shop-header-container .logo {
        transform: translateX(-105%);
    }

    #detail-images {
        --swiper-navigation-sides-offset: 10px !important;
    }
}