// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    .gs-tabs {

        nav {
            flex-direction: column;

            button {
                width: 100%;
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .filter-trigger {
    }

    .dropdown-picker[data-dropdown-type="pageSize"] {
        flex-grow: 1;

        .dropdown-button { 
            width: 100%;
        }
    }

    .dropdown-picker:not([data-dropdown-type="sort"]) {
        
    
        .dropdown-button {
            min-width: 0;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    #detail-images .swiper-button-prev {
        left: 0;
    }

    #detail-images .swiper-button-next {
        right: 0;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
    }

    .pills-group:before,
    .remove-all-filters {
        font-size: 11px;
    }

    .single-pill {
        font-size: 11px;
        padding: 2px 5px;
    }

    #pills-container:not(:empty) {
        margin-bottom: 20px;
    }

    .detail-amount button:before {
        font-size: 22px !important;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    .shop-header.stuck {
        top: -75px;
    }

    .shop-header-container {
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 8px !important;
        padding: 9px 0 8px 0 !important;

        .logo {
            order: -1 !important;
            position: static !important;
            transform: none !important;
            margin: 0 auto;
        }

        .header-tools-wrapper {
            width: 100%;
            margin-left: 0 !important;
            justify-content: space-evenly;
        }

        .header-contact {
            display: none !important;
        }
    }

    .promo-bar .headline {
        font-size: 21px !important;
    }

    .text-wrapper-container {

        .text-wrapper {
            top: 18px;
            gap: 8px;
            max-width: 95%;

            > b {
                font-size: 18px;
            }

            > span {
                display: none;
            }

            > .slide-button {
                font-size: 10px;
                margin-top: 8px;
                padding: 10px 18px;
            }
        }
    }

    .icon-banners-wrapper {
        gap: 16px;
    }

    .text-wrapper-container .text-wrapper > .slide-button.plays-video {
        width: 40px;
        height: 40px;

        &:before {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left: 14px solid white;
            left: calc(50% + 2px);
        }
    }

    a.cart-back-link {
        font-size: 10px;
        font-weight: 600;
        padding: 4px 7px 3px 5px;
        gap: 3px;

        &:before {
            font-weight: normal;
            font-size: 14px;
            position: relative;
        }
    }

    @media (max-width: 400px) {
        .text-wrapper-container .text-wrapper {
            top: 8px;

            > .slide-button {
                margin-top: 4px;
            }
        }
    }

    .swiper-pagination.banner-pagination .swiper-pagination-bullet {
        height: 32px;
    }
}

@media (max-width: 374px) {
    .navigation-wrapper {
        margin-top: 20px;
        flex-direction: column;
        gap: 8px;

        .dropdown-picker[data-dropdown-type="pageSize"] {
            width: 100%;

            .dropdown-button {
                min-width: 0;
                width: 100%;
            }
        } 
    }

    .filter-trigger {
        padding: 0 15px;
        margin-top: 4px !important;
        min-width: auto;
        justify-content: center;
    }
}