// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {


    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    .header-social-links {
        display: none;
    }

    .text-wrapper-container {

        .text-wrapper {
            top: 16px;
            gap: 8px;

            > .slide-button {
                margin-top: 8px;
            }
        }
    }

    @media (max-width: 820px) {
        .header-contact {
            flex-direction: column;
            gap: 8px;
            margin-block: -8px;
            align-items: flex-start !important;

            a {
                padding-left: 0;
                display: flex;
                gap: 6px;
                align-items: center;

                &:before {
                    font-size: 18px;
                    position: static;
                    transform: none;
                }
            }
        }

        .header-contact a.email:after {
            left: 11px;
            top: 1px;
            width: 6px;
            height: 6px;
        }
    }

    .shop-header-container .header-tools-wrapper {
        gap: 16px;
    }

    #detail-traits {
        gap: 0;

        .trait {
            flex-direction: column;
            gap: 6px;
            padding: 8px 0;

            b {
                width: 100%;
                justify-content: flex-start;
            }

            .trait-desc {
                width: 100%;
            }
        }
    }

    .alternating-banner,
    .mainpage-greeting {

        .headline {
            gap: 16px !important;
            margin-bottom: 16px !important;

            &:after {
                height: 18px !important;
            }
        }
    }

    .alternating-banner .alternating-text >
    a {
        margin-top: 16px;
    }

    .alternating-banner .alternating-text {
        margin-inline: 18px;
    }

    .header-contact a.email:after {
        top: 0;
    }

    .comment-response {
        margin-left: 16px;
    }

    .comment cite {
        padding: 18px 24px;
    }

    .comment-response {
        padding: 16px 24px;

        cite {
            padding: 6px 12px;
        }
    }

    @media (max-width: 840px) {
        .alternating-banners-wrapper {
            gap: calc(var(--rowGap) + 5px);
        }

        .alternating-banner {
            display: flex !important;
            flex-direction: column !important;
            align-items: center;
            gap: 16px;

            .alternating-image {
                width: 100%;
                max-width: 720px;
            }

            .alternating-text {
                order: 1 !important;
                text-align: center;
                align-items: center;

                a {
                    margin-bottom: 0 !important;
                }

                .headline:after {
                    display: none;
                }
            }
        }
    }

}