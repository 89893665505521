// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {
    :root {
        --fontSize: 13px;
        --lineHeight: 1.4;
    }

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .detail-omnibus {
        text-align: left;
    }

    main {
        padding-bottom: var(--rowGap) !important;
    }

    main.aside {
        grid-template-columns: minmax(0, 1fr);
        grid-gap: var(--rowGap) 0;
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;
        
        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 15px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 15px;
            }

            &.left-side-cookies {
                left: 15px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #container {
        overflow: clip;
    }

    #detail-images {

        .swiper-button-prev,
        .swiper-button-next {
            height: 80px;
            margin-top: -40px !important;
        }

        .swiper-button-prev {
            left: -45px;
        }

        .swiper-button-next {
            right: -45px;
        }
    }

    .headline {
        font-size: 26px !important;
        margin-bottom: 10px;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-bg {
        background-image: none !important;
    }

    .newsletter-input {
        width: 100%;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 10px;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
    }

    navigation-wrapper {
        gap: 0px 16px !important;
    }

    // FILTER
    #filter {
        display: none;
    }

    .product-images-mini {
        width: 100%;
        height: auto;
        margin-top: 10px;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .swiper-slide {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
        }

        img {
            display: none !important;
        }
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }


    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    :root {
        --iconSize: 20px;
    }

    .top-bar {
        padding-block: 4px;

        > div {
            padding: 0 26px;
        }

        .top-bar-close {
            right: 10px;
            width: 20px;

            &:before {
                font-size: 18px;
            }
        }
    }

    .header-contact {
        gap: 16px;
        
        a {
            padding-left: 26px;
        }

        a.email:after {
            top: -1px;
        }
    }

    #main-bar {
        display: none;
    }

    .shop-header-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 0;

        .logo {
            top: 50%;
            transform: translate(-50%, -50%);
            height: 68px;
            max-height: 100%;
        }

        .header-tools-wrapper {
            margin-left: auto;
            margin-bottom: 0;
            padding-bottom: 0;
            align-items: center;
            gap: 24px;
        }
    }

    .search-engine-trigger, .log-button, #cart-box, .tree-trigger {
        padding-top: 28px;

        &:before {
            font-size: 22px;
        }
    }

    .hamburger-icon {
        height: 22px;
        gap: 8px;

        > span {
            width: 85%;
        }
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: @header-color;
        .shadow();
        transition: transform .2s ease-in-out;
    }


    //// 1279 MAINPAGE

    .main-banner .swiper-slide {
        height: auto;
        aspect-ratio: ~"1440/640";
    }

    .text-wrapper-container {
        width: 100%;
    }

    .text-wrapper-container .text-wrapper {
        top: 32px;

        > b {
            font-size: 26px;
        }

        > span {
            font-size: var(--fontSize);
        }

        > .slide-button {
            padding: 10px 28px;
            margin-top: 12px;

            &.plays-video {
                width: 60px;
                height: 60px;

                &:before {
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 20px solid white;
                }
            }
        }
    }

    // 1279 GREETING
    .mainpage-greeting {
        padding-block: var(--rowGap);

        .headline {
            gap: 20px;
            margin-bottom: 20px;
        }
    }

    .icon-banners-wrapper {
        grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
        margin-top: var(--rowGap);
        gap: 32px 16px;
    }

    .icon-banner {
        gap: 8px;

        figure {
            width: 64px;
        }

        > p {
            font-size: 14px;
        }
    }

    // 1279 ALTERNATING BANNER
    .alternating-banner {

        .alternating-text {
            margin-inline: 32px;

            .headline {
                gap: 20px;
                margin-bottom: 20px;
            }

            > a {
                margin-top: 32px;
                padding: 10px 28px;
            }
        }
    }

    // 1279 PROMO BANNER
    .promo-bar {
        padding-block: 26px !important;
        gap: 16px 32px;

        a {
            padding: 10px 28px;
        }
    }

    // 1279 SLIDING BANNERS
    .sliding-banners-wrapper {
        margin-inline: 32px;
    }


    //// 1279 FOOTER
    .footer-content {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .logo {
            position: static;
            order: -1;
            gap: 20px;
            transform: none !important;
            height: 68px;
            align-self: center;
            margin-bottom: -6px;
        }
    }

    .footer-left,
    .footer-right {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        flex-wrap: nowrap;
        width: 100%;
        max-width: none;
    }
    
    .footer-bottom-bar {
        display: flex;
        flex-direction: column;
        padding-bottom: 40px;
    }

    //// 1279 LISTING
    .sidebar-banners-wrapper {
        display: none;
    }

    .inner.aside {
        display: flex;
        flex-direction: column;
    }

    main.aside {
        margin-top: 0px !important;
        margin-top: 0 !important;
    }

    #breadcrumb {
        padding-block: 10px !important;
    }

    .category-headline {
        margin-bottom: 10px;
    }

    .navigation-wrapper {
        margin-bottom: var(--rowGap);
    }

    .pagination {
        margin-top: calc(var(--rowGap) + 16px);
        gap: 32px;
    }

   body:not([data-type="detail"]) .product-badges {
        gap: 1px;

        span {
            font-size: 12px;
            font-weight: 600;
            padding: 2px 4px;
        }
    }

    a.product-name {
        margin-top: 12px;
    }

    @media (max-width: 900px) {
        .product-price {
            flex-direction: column;
            gap: 2px;
            align-items: flex-start;
        }
    }

    .short-desc {
        font-size: var(--fontSize);
    }

    //// 1279 DETAIL
    [data-type="detail"] #content {
        gap: var(--rowGap);
    }

    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 24px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev {
            left: 10px;
            height: 48px;
        }

        .swiper-button-next {
            right: 10px;
            height: 48px;
        }
    }

    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;

        .size-custom {
            padding: 0 !important;
        }
    }

    // 1279 DETAIL INFO
    #detail-info {

        > * {
            justify-content: center;
        }
    }

    .detail-headline {
        margin-top: 0;
        text-align: center;
        padding-left: 0;

        &:after {
            display: none;
        }
    }

    .detail-short-description {
        text-align: center;
    }

    .detail-availability-wrapper {
        text-align: center;
    }

    .shipment-popup-wrapper {
        display: flex;
        margin-top: 0;
        padding: 8px 0;
        margin-bottom: -8px;
        justify-content: center;
    }

    .detail-info-midsection {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        text-align: center;

        .detail-pricing {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-end;
            order: 1;

            > del,
            > .promo-text {
                position: relative;
                top: -4px;
            }

            > b {
                order: -1;
                font-size: 26px;
            }
        }

        .detail-omnibus {
            order: 2;
            top: 0;
            margin-top: -15px;
            align-self: center;
        }

        .detail-ask-button {
            order: 3;
            margin-bottom: -4px;
        }
    }

    .detail-options {
        display: flex;
        flex-direction: column;
        gap: inherit;
    }

    // 1279 DETAIL TABS
    [data-type="detail"] .headline {
        margin-bottom: 20px;
    }

    // 1279 DETAIL MAIN INFO
    .detail-main-info {
        margin-top: 0;
        gap: var(--rowGap);
    }

    // 1279 TRAITS
    #detail-traits .trait {
        gap: 24px;

        b {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            flex: 1;
        }

        .trait-desc {
            flex: 1;
        }
    }

    // 1279 COMMENTS
    .detail-comments-header {
        margin-bottom: 20px;
        gap: 4px 16px;

        .stars-rating.average-rating {
            top: 1px;
        }

        &:after {
            margin-left: 0;
        }
    }

    .total-votes-wrapper {
        top: -8px;
    }

    .comments-list {
        display: flex;
        flex-direction: column;
        gap: var(--rowGap);
        margin-bottom: 0;
    }

    .comment-info .added-date {
        margin-left: 0;
    }

    .comment:last-child .comment-response {
        margin-bottom: 20px;
    }

    #content:has(.infobox) {
        grid-column: 1 / 3 !important;
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {

            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}